import axios from 'axios';
import firebase from 'firebase/app';
import isEmail from 'validator/lib/isEmail';
import * as Sentry from '@sentry/browser';
import { mails, fbFunctions } from '~/plugins/firestore';
import {
  RISK_CATEGORIES,
  EMPLOYER_IDS,
  PRODUCT_CODES
} from '~/constants/index';
import {
  getPensionBeeFundListData,
  getAvcWiseFundListData
} from '~/plugins/apiFunctions';

export function generateId() {
  const chars =
    '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const length = 20;
  let result = '';
  for (let i = length; i > 0; --i)
    result += chars[Math.floor(Math.random() * chars.length)];
  return result;
}

export async function getPlacesFromPostcode(postcode) {
  const resp = await axios.get(
    `https://api.getaddress.io/find/${escape(
      postcode
    )}?api-key=zWMzGchD7ESbx6IXNILrDA27362&sort=true`
  );
  if (resp.status === 200) {
    return resp.data.addresses;
  } else {
    return false;
  }
}

export function isValidEmail(email) {
  return isEmail(email);
}

export async function sendMail(mailData) {
  // mailData Object will contain all the necessary information to send mail i.e. subject, to, name, etc.
  await mails.add({
    to: [mailData.to],
    message: mailData.message
  });
}

export async function addSubscriber(serialized) {
  serialized += '&' + encodeURIComponent('group[70236][1]') + '=' + 'true';
  let urlString =
    'https://agewage.us4.list-manage.com/subscribe/post-json?u=0730a695c14f3dd04f4c20221&amp;id=504b60c565';
  urlString += serialized + `&c=displayMailChimpStatus`;
  const script = window.document.createElement('script');
  script.src = urlString;
  const ref = window.document.getElementsByTagName('script')[0];
  ref.parentNode.insertBefore(script, ref);
  script.onload = function(data) {
    this.remove();
  };
}

export function getRetirementAge(birthDate) {
  let retirementAge = 0;
  let currAge = 0;
  if (birthDate) {
    const day = birthDate && birthDate.split('-')[0];
    const month = birthDate && birthDate.split('-')[1];
    const year = birthDate && birthDate.split('-')[2];
    const bdate = new Date();
    bdate.setFullYear(year);
    bdate.setMonth(parseInt(month) - 1);
    bdate.setDate(day);
    if (bdate !== 'Invalid Date') {
      currAge = new Date().getFullYear() - parseInt(year);
      if (
        bdate > new Date('1954', '09', '06') &&
        bdate < new Date('1961', '02', '05')
      ) {
        retirementAge = 66;
      } else if (
        bdate > new Date('1961', '02', '06') &&
        bdate < new Date('1978', '03', '05')
      ) {
        retirementAge = 67;
      } else if (bdate > new Date('1978', '03', '00')) {
        retirementAge = 68;
      }
    } else {
      retirementAge = '';
    }
  }
  return { retirementAge, currAge };
}

export function getMonthlyInCome(retirementAge) {
  switch (retirementAge) {
    case 66:
      return 277;
    case 67:
      return 292;
    case 68:
      return 300;
    default:
      return 277;
  }
}

export function getProviderImageList() {
  const providerList = [
    {
      imgUrl: require('../assets/Provider-logos/standard-life-new.jpg'),
      id: '1Ujosf2yWEY3y309Jn6C'
    },
    {
      imgUrl: require('../assets/Provider-logos/Aegon.png'),
      id: 'WMCNGh2bHnQwEkADLiU2'
    },
    {
      imgUrl: require('../assets/Provider-logos/Phoenix-Life.png'),
      id: 'TXoA5pJbYIyzeNJu8EAl'
    },
    {
      imgUrl: require('../assets/Provider-logos/pensionbee-border.svg'),
      id: 'xFZjfpQaaJJYLiFUqBfY'
    },
    {
      imgUrl: require('../assets/Provider-logos/royal-london.jpeg'),
      id: '99YteTnmi8z5ZV7REtF4'
    },
    {
      imgUrl: require('../assets/Provider-logos/Nest_Header_Logo.png'),
      id: 'q0HIykvuJIO5yLM6kqDN'
    },
    {
      imgUrl: require('../assets/Provider-logos/aviva.jpg'),
      id: 'SQVMWQiCmN7DC7m6JMyI'
    },
    {
      imgUrl: require('../assets/Provider-logos/fidelity-small.png'),
      id: 'q0HIykvuJIO5yLM6kqDN'
    },
    {
      imgUrl: require('../assets/Provider-logos/hlawards2.png'),
      id: 'WpK4xqDBYNPOOErdGUnO'
    },
    {
      imgUrl: require('../assets/Provider-logos/legal-Gen.png'),
      id: '0TUsgJukvv0Zvhe8l1n3'
    },
    {
      imgUrl: require('../assets/Provider-logos/now_rgb.jpg'),
      id: 'kOORzfLotnBSc2fAnlad'
    },
    {
      imgUrl: require('../assets/Provider-logos/pru-logo.png'),
      id: '8SrcEXQzvCR9VZGaFWNK'
    },
    {
      imgUrl: require('../assets/Provider-logos/smart.png'),
      id: 'XBoDvvBTm0FeumD8tJv6'
    },
    {
      imgUrl: require('../assets/Provider-logos/peoples.jpg'),
      id: 'Ivs0HTTlycn4eBGYpFRN'
    },
    {
      imgUrl: require('../assets/Provider-logos/SW-Logo-2.svg'),
      id: 'I8p4GeNrIV4azDjyLzk3'
    }
  ];
  return providerList;
}

export function isMobile() {
  return window.innerWidth < 500;
}

export function getOptimizedImage(src) {
  const ext = src.split('.')[1];
  const path = src.split('.')[0] + '@1x';
  let imageUrl = '';
  if (isMobile()) {
    imageUrl = require(`../assets/${path}.${ext}`);
  } else {
    imageUrl = require(`../assets/${src}`);
  }
  return imageUrl;
}

export async function logOut() {
  Calendly.destroyBadgeWidget();
  const calendlyWidgetElem = document.getElementById('calendly-widget');
  if (calendlyWidgetElem) {
    calendlyWidgetElem.classList.remove('d-none');
  }
  await firebase.auth().signOut();
  await window.$nuxt.$store.commit('reset');
  await window.$nuxt.$store.commit('authUser', false);
  Sentry.setUser(null);
  console.log('hereree for logout');
  await window.$nuxt.$router.push({
    path: '/onboarding/login',
    name: 'onboarding-login'
  });
}

export async function downloadPensionReport(reportUrl, reportName) {
  axios({
    url: reportUrl,
    method: 'GET',
    responseType: 'blob'
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', reportName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  });
}

export function getCurrYear() {
  const year = new Date().getFullYear();
  return year;
}

export function getCurrAge(birthDate) {
  const year = birthDate && birthDate.split('-')[2];
  const currAge = new Date().getFullYear() - parseInt(year);
  return currAge;
}
export function getUserRiskCategory(yearsToretirement) {
  if (yearsToretirement <= 5) {
    return RISK_CATEGORIES.VERY_LOW;
  }
  if (yearsToretirement > 2 && yearsToretirement <= 5) {
    return RISK_CATEGORIES.LOWER;
  }
  if (yearsToretirement > 5 && yearsToretirement <= 10) {
    return RISK_CATEGORIES.MEDIUM;
  }
  if (yearsToretirement > 10) {
    return RISK_CATEGORIES.HIGHER;
  }
}

export async function setUserFundList(current) {
  const avcWiseSelection = current.$store.state.user.avcWiseSelection;
  const currAge = getCurrAge(current.$store.state.user.personalInfo.ur_dob);
  const yearsToretirement = avcWiseSelection.retirementAge - currAge;
  const avcFundList = await getAvcWiseFundListData();
  const userFundList = avcFundList.filter(
    (fund) => fund.employerId == avcWiseSelection.employerId
  );
  let filteredFundList = [];
  let defaultAvcFund = {};
  if (userFundList.length > 0) {
    defaultAvcFund = userFundList.find((fund) => fund.isDefault == true) || {};
    filteredFundList = [...userFundList];
  }

  const rangeVal = getLifestyleRange(
    yearsToretirement,
    avcWiseSelection.employerId
  );
  const finalFundList = filteredFundList.map((fund) => {
    let fundToAdd = { ...fund };
    if (fund.isLifeStyle) {
      const lifeStyleScore = fund.lifestlyeScore[rangeVal];
      fundToAdd = { ...fund, ...lifeStyleScore };
    }
    return fundToAdd;
  });

  if (defaultAvcFund.isLifeStyle) {
    const lifeStyleScore = defaultAvcFund.lifestlyeScore[rangeVal];
    defaultAvcFund = { ...defaultAvcFund, ...lifeStyleScore };
  }

  current.$store.commit('setUserFunds', finalFundList);
  return {
    finalFundList,
    defaultAvcFund,
    yearsToRetirement: yearsToretirement,
    savingOption: avcWiseSelection.savingOption
  };
}

export function getEmployerImageUrl(selection) {
  let imgUrl = '';
  if (selection && selection.employerId) {
    if (selection.employerId == 'aZus7sJufJIc6HwhwrH0') {
      imgUrl = require('../assets/AvcWise/KIRKLEES COUNCIL LOGO.jpg');
    }
    if (selection.employerId == '9nEmLmsnbLXpRkA0qRO9') {
      imgUrl = require('../assets/AvcWise/HDC-Logo.jpg');
    }
  }
  return imgUrl;
}

export function getColor(rating) {
  let colorCode = '';
  if (rating === RISK_CATEGORIES.HIGHER) {
    colorCode = '#ff0000';
  } else if (rating === RISK_CATEGORIES.LOWER) {
    colorCode = '#008000';
  } else if (rating === RISK_CATEGORIES.MEDIUM) {
    colorCode = '#ffa500';
  } else if (rating === RISK_CATEGORIES.VERY_LOW) {
    colorCode = '#808080';
  } else {
    colorCode = '#ccc';
  }
  return colorCode;
}

export function getRiskImg(riskVal) {
  if (riskVal == RISK_CATEGORIES.LOWER) {
    return require('../assets/risk_profile_low.svg');
  } else if (riskVal == RISK_CATEGORIES.MEDIUM) {
    return require('../assets/risk_profile_medium.svg');
  } else if (riskVal == RISK_CATEGORIES.HIGHER) {
    return require('../assets/risk_profile_high.svg');
  }
  return require('../assets/risk_profile_low.svg');
}

export async function setUserFundLists() {
  const userFundList = await getPensionBeeFundListData();
  return userFundList || [];
}

export function getlifestyleRange(bYear) {
  let range = '';
  switch (true) {
    case bYear <= 1959:
      range = '<1959';
      break;
    case bYear >= 1960 && bYear <= 1965:
      range = '1960-1965';
      break;
    case bYear >= 1966 && bYear <= 1971:
      range = '1966-1971';
      break;
    case bYear >= 1972 && bYear <= 1977:
      range = '1972-1977';
      break;
    case bYear >= 1978 && bYear <= 1983:
      range = '1978-1983';
      break;
    case bYear >= 1984 && bYear <= 1989:
      range = '1984-1989';
      break;
    case bYear >= 1990 && bYear <= 1995:
      range = '1990-1995';
      break;
    case bYear >= 1996:
      range = '>1996';
      break;
  }
  return range;
}

export function callGAEvent(gtag, eventVal) {
  if (eventVal.extraParam) {
    gtag.event(eventVal.eventName, {
      event_category: eventVal.category,
      event_label: eventVal.label,
      ...eventVal.extraParam
    });
  } else {
    gtag.event(eventVal.eventName, {
      event_category: eventVal.category,
      event_label: eventVal.label
    });
  }
}

export function openCalendlyWidget() {
  Calendly.initPopupWidget({
    url: 'https://calendly.com/d/dpw-ff3-gfp/30-min-pension-guidance-session',
    textColor: '#ffffff',
    branding: false
  });
}

export function getLifestyleRange(yearsToretirement, employerId) {
  if (yearsToretirement >= 0 && yearsToretirement < 1) {
    return '>0';
  } else if (yearsToretirement >= 1 && yearsToretirement < 2) {
    return '>1';
  } else if (yearsToretirement >= 2 && yearsToretirement < 3) {
    return '>2';
  } else if (yearsToretirement >= 3 && yearsToretirement < 4) {
    return '>3';
  } else if (yearsToretirement >= 4 && yearsToretirement < 5) {
    return '>4';
  } else if (
    yearsToretirement >= 5 &&
    yearsToretirement < 6 &&
    employerId == EMPLOYER_IDS.HORSHAM
  ) {
    return '>5';
  } else if (
    yearsToretirement >= 6 &&
    yearsToretirement < 7 &&
    employerId == EMPLOYER_IDS.HORSHAM
  ) {
    return '>6';
  } else if (
    yearsToretirement >= 7 &&
    yearsToretirement < 8 &&
    employerId == EMPLOYER_IDS.HORSHAM
  ) {
    return '>7';
  } else if (yearsToretirement >= 8 && employerId == EMPLOYER_IDS.HORSHAM) {
    return '>8';
  } else {
    return '>5';
  }
}

export function getEmployerValue(avcwiseInfo) {
  const orgName = avcwiseInfo.organisation_name || '';
  let employerId = '';
  if (
    orgName &&
    (orgName.toLowerCase().includes('kirklees') ||
      orgName.toLowerCase() == 'kirklees council')
  ) {
    employerId = EMPLOYER_IDS.KIRKLEES;
  }

  if (
    orgName &&
    (orgName.toLowerCase().includes('horsham') ||
      orgName.toLowerCase() == 'horsham district council')
  ) {
    employerId = EMPLOYER_IDS.HORSHAM;
  }

  return employerId;
}

export async function generateAVCWiseReport(user) {
  const generateAVCWiseReport = fbFunctions.httpsCallable(
    'generateAVCWiseReport'
  );
  if (user.avcwiseuser && user.avcWiseSelection) {
    return generateAVCWiseReport({ userId: user.documentId }).then((result) => {
      return true;
    });
  }
}
export function getSuezNarative(age, score) {
  let scoreRange = '';
  switch (true) {
    case score >= 0 && score <= 20:
      scoreRange = '0-20';
      break;
    case score > 20 && score <= 50:
      scoreRange = '20-50';
      break;
    case score > 50 && score <= 80:
      scoreRange = '50-80';
      break;
    case score > 80 && score <= 100:
      scoreRange = '80-100';
      break;
    default:
      scoreRange = '0-20';
      break;
  }
  const ageRange = age <= 50 ? '<50' : '>50';
  return { scoreRange, ageRange };
}

const taxRange = [
  {
    rangeMin: 0,
    rangeMax: 12570,
    interest: 0
  },
  {
    rangeMin: 12571,
    rangeMax: 50270,
    interest: 20
  },
  {
    rangeMin: 50271,
    rangeMax: 125140,
    interest: 40
  },
  {
    rangeMin: 125141,
    rangeMax: -1,
    interest: 45
  }
];

export function calculateTaxAmount(amount, totalTax) {
  const taxVal = 0;
  const rangeVal = 0;
  if (amount && totalTax) {
    const total = taxRange.reduce((acc, curr) => {
      const { rangeMin, rangeMax, interest } = curr;

      // initial amount range check
      if (amount > rangeMax && rangeMax !== -1) {
        return acc;
      }

      if (rangeMax < totalTax && rangeMax !== -1) {
        const minVal = amount > rangeMin ? amount : rangeMin;
        acc += (rangeMax - minVal) * (interest / 100);
        return acc;
      }

      if (rangeMax > totalTax && rangeMax !== -1) {
        const minVal = amount > rangeMin ? amount : rangeMin;
        acc += (totalTax - minVal) * (interest / 100);
        return acc;
      }

      // when reach to last slab
      if (rangeMax === -1 && rangeMin < totalTax) {
        const minVal = amount > rangeMin ? amount : rangeMin;
        acc += (totalTax - minVal) * (interest / 100);
        return acc;
      }
      return acc;
    }, 0);
    return {
      taxVal: (total && +total > 0 && +total.toFixed(0)) || 0,
      rangeVal: 0
    };
  }
  return { taxVal, rangeVal };
}

export function filter(evt) {
  evt = (evt && evt) || window.event;
  const expect = evt.target.value.toString() + evt.key.toString();

  if (!/^[-+]?[0-9]*\.?[0-9]*$/.test(expect)) {
    evt.preventDefault();
  } else {
    return true;
  }
}

export function getThresholdIncomeVals(userData) {
  const taxYear = isNextYearCalc(userData.pensionDetails)
    ? '2023-2024'
    : '2022-2023';
  const currYear = isNextYearCalc(userData.pensionDetails) ? 2023 : 2022;

  const savingsData = userData.pensionSavings;
  let salarySacrifice = 0;
  let employeeVal = 0;
  let employerVal = 0;
  savingsData.length > 0 &&
    savingsData.forEach((pension) => {
      salarySacrifice =
        salarySacrifice + Number(pension.employerContribution[currYear] || 0);
      employeeVal =
        employeeVal + Number(pension.employeeContribution[currYear] || 0);
      employerVal =
        employerVal + Number(pension.employerContribution[currYear] || 0);
    });
  const salarySacrificeVal = { salarySacrifice, employeeVal, employerVal };
  const thresholdBreakdown = {
    taxableIncome: Number(userData.pensionDetails.taxableIncome[taxYear] || 0),
    salarySacrifice: salarySacrificeVal.salarySacrifice,
    employeeContrib: salarySacrificeVal.employeeVal
  };
  thresholdBreakdown.thresholdIncome = Number(
    thresholdBreakdown.taxableIncome +
      thresholdBreakdown.salarySacrifice -
      thresholdBreakdown.employeeContrib
  );
  const adjustedBreakdown = {
    taxableIncome: Number(userData.pensionDetails.taxableIncome[taxYear] || 0),
    salarySacrifice: salarySacrificeVal.salarySacrifice,
    dbPensionVal: Number(userData.pensionDetails.pensionBenefit[currYear] || 0),
    employeeContrib: salarySacrificeVal.employeeVal,
    occupationalPension: 0
  };
  adjustedBreakdown.adjustedIncome = Number(
    Number(adjustedBreakdown.taxableIncome) +
      Number(adjustedBreakdown.salarySacrifice) +
      Number(adjustedBreakdown.occupationalPension) +
      Number(adjustedBreakdown.dbPensionVal) -
      Number(adjustedBreakdown.employeeContrib)
  );
  return { thresholdBreakdown, adjustedBreakdown };
}

export function getProductCode(productNameFE) {
  if (productNameFE == PRODUCT_CODES.pensionbee) {
    return 'pensionbee';
  }
  if (productNameFE == PRODUCT_CODES.suez) {
    return 'suez';
  }
  if (productNameFE == PRODUCT_CODES.avcwise) {
    return 'avcwise';
  }
  if (productNameFE == PRODUCT_CODES.firefighter) {
    return 'firefighter';
  }
  if (productNameFE == PRODUCT_CODES.psh) {
    return 'psh';
  }
  return 'agewage';
}

export function isPastYearCalc(pensiondetails) {
  if (pensiondetails.calculationYear == '2022-23') {
    return true;
  }
  return false;
}

export function isNextYearCalc(pensiondetails) {
  if (pensiondetails.calculationYear == '2023-24') {
    return true;
  }
  return false;
}

export function getMinAllowance(pensionDetails) {
  if (isNextYearCalc(pensionDetails)) {
    return 10000;
  } else {
    return 4000;
  }
}
export function getAllowanceVal(pensionDetails) {
  if (isNextYearCalc(pensionDetails)) {
    return 60000;
  } else {
    return 40000;
  }
}

export function getAdjustedLimit(pensionDetails) {
  if (isNextYearCalc(pensionDetails)) {
    return 260000;
  } else {
    return 240000;
  }
}
