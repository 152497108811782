<template>
  <div class="overflow-hidden">
    <v-app>
      <DashboardHeader />
      <nuxt />
      <OnboardingFooter />
      <Snackbar />
    </v-app>
  </div>
</template>

<script>
import firebase from 'firebase/app';
import 'firebase/auth';
import * as Sentry from '@sentry/browser';
import { logOut } from '~/plugins/utils';
import { getUserStatus, getNotificationObjData } from '~/plugins/apiFunctions';
import DashboardHeader from '~/components/Header/DashboardHeader.vue';
import OnboardingFooter from '~/components/Footer/OnboardingFooter.vue';
import Snackbar from '~/components/Snackbar.vue';
import { PRODUCT_CODES } from '~/constants';

export default {
  components: {
    DashboardHeader,
    OnboardingFooter,
    Snackbar
  },
  async mounted() {
    const that = this;
    let authFlag = false;
    this.$nuxt.$emit('loading', true);
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user && !authFlag) {
        const userData = await getUserStatus(user);
        if (userData) {
          if (
            (userData.suezuser && this.$productName !== PRODUCT_CODES.suez) ||
            (userData.pensionbeeuser &&
              this.$productName !== PRODUCT_CODES.pensionbee) ||
            (userData.avcwiseuser &&
              this.$productName !== PRODUCT_CODES.avcwise)
          ) {
            return;
          }
          if (
            !user.emailVerified &&
            !userData.pensionbeeuser &&
            !userData.suezuser &&
            !userData.avcwiseuser &&
            !userData.firefighteruser &&
            !userData.pshuser &&
            this.$productName !== PRODUCT_CODES.suez &&
            this.$productName !== PRODUCT_CODES.pensionbee &&
            this.$productName !== PRODUCT_CODES.avcwise &&
            this.$productName !== PRODUCT_CODES.firefighter &&
            this.$productName !== PRODUCT_CODES.psh
          ) {
            this.$router.push({
              path: '/verify-email',
              name: 'verify-email'
            });
            that.$store.commit('authUser', true);
          }

          if (
            user.emailVerified &&
            (!userData.personalInfo.firstName ||
              !userData.personalInfo.lastName ||
              !userData.personalInfo.ur_dob)
          ) {
            this.$router.push({
              path: '/onboarding/personal-info',
              name: 'onboarding-personal-info'
            });
          }
          // set other objects in the state like personal info and pensionhistory
          authFlag = true;
          try {
            that.$store.commit('updateDocumentId', userData.documentId);
            that.$store.commit('updatePersonalInfo', userData.personalInfo);

            if (userData.pensionbeeuser) {
              that.$store.commit(
                'updatePensionBeeStatus',
                userData.pensionbeeuser
              );
              that.$store.commit(
                'updatePensionBeeInfo',
                userData.pensionBeeInfo
              );
              that.$store.commit(
                'updateShortlistedFund',
                userData.shortlistedFund
              );
            }

            if (userData.avcwiseuser) {
              that.$store.commit('updateTourStatus', userData.tourCompleted);
              that.$store.commit(
                'updateAVCWiseSelection',
                userData.avcWiseSelection
              );

              that.$store.commit('updateAVCWiseInfo', userData.AVCWiseInfo);
              that.$store.commit(
                'updateShortlistedFunds',
                userData.shortlistedAvcFunds
              );
              that.$store.commit('setAVCEWiseFlag', userData.avcwiseuser);
            }

            if (userData.suezuser) {
              that.$store.commit('setSuezFlag', userData.suezuser);
              that.$store.commit(
                'updateDefaultReportIds',
                userData.defaultReportIds
              );
            }

            if (userData.pshuser) {
              that.$store.commit('setPshFlag', userData.pshuser);
              that.$store.commit('updatePshData', userData.pshData);
            }

            if (userData.firefighteruser) {
              that.$store.commit(
                'updateFireFighterStatus',
                userData.firefighteruser
              );
              that.$store.commit(
                'updatePensionDetails',
                userData.pensionDetails
              );
              that.$store.commit(
                'updatePensionSavings',
                userData.pensionSavings
              );
              that.$store.commit('updateTaxLiability', userData.taxLiability);
              that.$store.commit('updateMpaa', userData.mpaa);
            }

            that.$store.commit('updatePensionHistory', userData.pensionHistory);
            that.$store.commit('updateEmploymentInfo', userData.employmentInfo);
            that.$store.commit('updatePensionStatus', userData.pensionStatus);
            that.$store.commit('updateSignature', userData.signature);
            that.$store.commit('updateCreationTime', userData.createdAt);
            that.$store.commit('updateDocuments', userData.documents);
            that.$store.commit(
              'updateCheckoutSessionId',
              userData.checkoutSessionId
            );
            that.$store.commit(
              'updateCheckoutPensions',
              userData.checkoutPensions
            );
            that.$store.commit('updatePaidPensions', userData.paidPensions);
            that.$store.commit('authUser', true);

            const notificationsData = await getNotificationObjData(
              that.$store.state.user.documentId
            );

            this.$gtag.set({ user_id: that.$store.state.user.documentId });
            Sentry.setUser({ email: user.email });
            that.$store.commit('updateNotifications', notificationsData);
            if (userData.avcwiseuser) {
              const calendlyWidgetElem = document.getElementById(
                'calendly-widget'
              );
              if (calendlyWidgetElem) {
                calendlyWidgetElem.classList.remove('d-none');
              }
              if (!that.$store.state.user.avcWiseSelection.employerId) {
                this.$router.push({
                  path: '/onboarding/employment-info',
                  name: 'onboarding-employment-info'
                });
                this.$nuxt.$emit('loading', false);
              } else if (
                !that.$store.state.user.avcWiseSelection.retirementAge
              ) {
                this.$router.push({
                  path: '/onboarding/fund-selection',
                  name: 'onboarding-fund-selection'
                });
                this.$nuxt.$emit('loading', false);
              }
            }
            this.$nuxt.$emit('loading', false);
          } catch (err) {
            this.$nuxt.$emit('loading', false);
            await logOut(this);
          }
        }
      } else {
        this.$store.commit('authUser', false);
        this.$nuxt.$emit('loading', false);
        const routeUrl = this.$router.history.current.fullPath.replace(
          /\/+$/,
          ''
        );
        const routerString = routeUrl.split('/');
        const urlList = [
          'login',
          'login-error',
          'personal-info',
          'reset-password'
        ];
        if (!urlList.includes(routerString[routerString.length - 1])) {
          this.$router.push({
            path: '/onboarding/personal-info',
            name: 'onboarding-personal-info'
          });
        }
      }
      this.$nuxt.$emit('loading', false);
    });
  }
};
</script>
