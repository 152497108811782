import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { GraphChart, BarChart } from 'echarts/charts';
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent
} from 'echarts/components';

import Vue from 'vue';
import VChart from 'vue-echarts';

use([
  CanvasRenderer,
  GraphChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  BarChart,
  GridComponent
]);

Vue.use(VChart);
