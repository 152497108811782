import Vue from 'vue';
import VueGtag from 'vue-gtag';

export default (context, inject) => {
  console.log(context.env.GA_CONTAINER_ID);
  Vue.use(VueGtag, {
    config: { id: context.env.GA_CONTAINER_ID },
    debug_mode: true
  });
};
