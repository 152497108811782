import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';

const firebaseConfig = {
  apiKey: 'AIzaSyARkxw8fvde13GJ4996Rsn0TagfKObz-g8',
  authDomain: 'aw-onboard-test.firebaseapp.com',
  databaseURL: 'https://aw-onboard-test.firebaseio.com',
  projectId: 'aw-onboard-test',
  storageBucket: 'aw-onboard-test.appspot.com',
  messagingSenderId: '548810662329',
  appId: '1:548810662329:web:298bf43a16100469909668',
  measurementId: 'G-ZMTYM4C4BC'
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

export const db = firebase.firestore();
export const fbFunctions = firebase.functions();
export const users = db.collection('users');
export const notifications = db.collection('notifications');
export const storageRef = firebase.storage().ref();
export const mails = db.collection('mail');
export const activity = db.collection('activity');
export const resetpass = db.collection('resetpass');
export const feedbacks = db.collection('feedbacks');
export const avcWiseEmployers = db.collection('avcwiseemployers');
export const avcWiseFundList = db.collection('avcwisefunds');
export const suezUserData = db.collection('suezdata');
export const pensionbeeFundList = db.collection('pensionbeefunds');
