import Vue from 'vue';

const suezProduct = 'agewage-suez';
const mainProduct = 'agewage-main';
const pensionbeeProduct = 'agewage-pensionbee';
const avcwiseProduct = 'agewage-avcwise';
const firefighterProduct = 'agewage-firefighter';
const pshProduct = 'agewage-psh';
export default (context, inject) => {
  console.log(context.env.PRODUCT_NAME, 'ffff');
  inject('productName', Vue.observable(context.env.PRODUCT_NAME));
  // inject('productName', Vue.observable(avcwiseProduct));
};
