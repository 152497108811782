console.log('hereere');
export default async (context) => {
  console.log('hereere insider-----------');
  const workbox = await window.$workbox;
  console.log('workbox found-----------', workbox);

  if (!workbox) {
    console.log('workbox not found-----------');
    console.log("Workbox couldn't be loaded.");
    return;
  }

  workbox.addEventListener('installed', async (event) => {
    console.log('added event listener', event, event.isUpdate);
    if (!event.isUpdate) {
      console.log('The PWA is on the latest version.');
      return;
    }

    console.log('There is an update for the PWA, reloading...');
    caches.keys().then(function(cacheNames) {
      cacheNames.forEach(function(cacheName) {
        caches.delete(cacheName);
      });
    });
    delete window.localStorage;
    window.location.reload();
  });
};
