import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';

Sentry.init({
  dsn:
    'https://469c366c03c94cde9de611d82f06cff6@o405974.ingest.sentry.io/5272495',
  integrations: [
    new VueIntegration({ Vue, attachProps: true, logErrors: true })
  ],
  environment: process.env.environment,
  release: process.env.commitId || 'undefined-agewage' // add variable from CI
});
